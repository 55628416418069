@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body{
    margin: 0;
    padding:0;
    font-family: "Open Sans", sans-serif;
}
.unclickable-row {
    pointer-events: none;
    cursor: default;
  }
 

@tailwind base;
@tailwind components;
@tailwind utilities;